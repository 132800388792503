@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.about-app {
  display: flex;
  max-height: 30rem;
  min-height: 10rem;
  max-width: $max-width;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;
  @include media-breakpoint-up(lg) {
    margin-top: $safety-margin;
  }

  @include media-breakpoint-down(xxl) {
    margin-left: $safety-margin;
    margin-right: $safety-margin;
  }

  @include media-breakpoint-down(lg) {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    flex-direction: column;
    max-height: 55rem;
    margin-bottom: $safety-margin;
  }

  &__image {
    text-align: left;
    flex-shrink: 0;
    height: auto;
    min-width: 20rem;
    max-width: 100%;
    z-index: 1;
    position: relative;
    margin-right: 1rem;
  }

  &__text {
    text-align: right;
    z-index: 2;
    flex: 1;
    min-width: 0;
    @include media-breakpoint-down(lg) {
      order: 2;
      text-align: center;
    }

    .about-app__title {
      @extend .headline-extra-large;
      margin-bottom: $spacing-small;
    }
    .about-app__description {
      @extend .body-medium;
    }
  }
}
