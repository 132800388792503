@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.form-section {
  text-align: center;
  margin-top: $safety-margin-large;
  max-height: 50rem;
  min-height: 10rem;
}

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $max-width;
  margin: 0 auto;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    margin: $spacing-large $safety-margin-small;
    max-height: 55rem;
  }
}

.form__image {
  text-align: left;
  max-height: 30rem;
}

.form-container {
  background-color: var(--background);
  padding: $spacing-medium;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--shadows);
  max-width: 35rem;
  width: 100%;
}

.form__main-title {
  @extend .headline-extra-large;
  margin-bottom: $spacing-large;
}

.form__title {
  @extend .headline-large;
  text-align: center;
  margin-bottom: $spacing-large;
}

.form__group {
  margin-bottom: $spacing-small;
}

.form__label {
  display: block;
  @extend .body-medium;
}

.form__input,
.form__textarea {
  width: 100%;
  padding: $spacing-small;
  background: var(--tertiary);
  border-radius: 5px;
  border: none;
  outline: none;

  &:focus {
    border: 1.5px solid var(--primary);
  }
}

.form__textarea {
  resize: vertical;
  height: 100px;
}

.form__button {
  width: 100%;
  padding: 15px;
  margin-top: $spacing-small;
  background-color: var(--primary);
  color: var(--onBrightElements);
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryActive);
  }
}
