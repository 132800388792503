@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.home {
  max-height: 30rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: $max-width;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: $safety-margin;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    margin: $safety-margin-large $safety-margin-small 0 $safety-margin-small;
    align-items: center;
    max-height: 50rem;
  }

  @include media-breakpoint-down(xxl) {
    margin-left: $safety-margin;
  }
  @include media-breakpoint-down(lg) {
    margin-left: $safety-margin-small;
    margin-bottom: $safety-margin;
  }

  &__image--large {
    text-align: right;
    flex-shrink: 0;
    height: auto;
    min-width: 20rem;
    max-width: 100%;
    z-index: 1;
    position: relative;
    margin-left: 1rem;

    @include media-breakpoint-down(xxl) {
      margin-right: $safety-margin;
    }
  }
  &__image--small {
    order: 1;
  }
  &__text {
    text-align: left;
    z-index: 2;
    margin-top: $spacing-small;
    flex: 1;
    min-width: 0;
    @include media-breakpoint-down(lg) {
      order: 2;
      text-align: center;
    }
  }
  .home__title {
    @extend .headline-extra-large;
    white-space: nowrap;
    margin-bottom: $spacing-small;
  }

  .home__description {
    @extend .body-medium;
    white-space: nowrap;
  }
  .home__highlight {
    @extend .body-large;
  }
}
