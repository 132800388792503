@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.custom-navbar {
  background-color: var(--tertiary);

  .navbar-container {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .navbar-brand {
    width: 12rem;
    height: auto;

    @include media-breakpoint-down(xxl) {
      margin-left: $safety-margin;
    }

    @include media-breakpoint-down(lg) {
      margin-left: $safety-margin-small;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .navbar-toggler {
    margin-left: auto;
    margin-right: $safety-margin-small;
  }

  .navbar-nav {
    @include media-breakpoint-down(xxl) {
      margin-right: $safety-margin;
    }
    @include media-breakpoint-down(lg) {
      .nav-item {
        margin-bottom: $spacing-small;

        &:first-child {
          margin-top: $spacing-medium;
        }
      }
    }
  }

  .nav-item {
    &.active {
      .nav-link,
      .navbar__icon {
        color: var(--primary);
      }
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    @extend .body-medium;

    &:hover,
    &:active {
      color: var(--primary);
    }
  }

  .navbar__icon {
    margin-left: $spacing-small;
    margin-right: $spacing-extra-small;
    font-size: $large-text;
    color: var(--secondary);

    &:hover,
    &:active {
      color: var(--primary);
    }
  }

  @include media-breakpoint-down(lg) {
    .navbar-collapse {
      position: absolute;
      top: $spacing-extra-large;
      right: -15rem;
      width: 15rem;
      min-height: 65vh;
      overflow-y: auto;
      background-color: var(--tertiary);
      transition: right 0.5s ease;

      &.show {
        right: 0;
        border-radius: 0.5rem;
      }
    }
  }
}

.hamburger-icon {
  color: var(--secondary);
}
