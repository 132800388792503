//variables for light theme
:root {
  --primary: #e7b824;
  --primaryActive: #ffc50c;
  --secondary: #636363;
  --tertiary: #ffffff;
  --background: #f0f4f2;
  --onBrightElements: #000;
  --shadows: #a0a0a0;
  --eco: #32cd32;
}
// variables for dark theme

[data-theme="dark"] {
  --primary: #e7b824;
  --primaryActive: #ffc50c;
  --secondary: #c3c3c3;
  --tertiary: #212121;
  --background: #333;
  --onBrightElements: #fff;
  --shadows: #212121;
  --eco: #32cd32;
}
