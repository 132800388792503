@use "./theme/reset";
@use "./theme/colors";
@use "./theme/fonts";
@use "./theme/variables";

@use "./sections/header";
@use "./sections/home";
@use "./sections/about_app";
@use "./sections/functions";
@use "./sections/about_us";
@use "./sections/contact_form";
@use "./sections/footer";

@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "bootstrap/scss/bootstrap";
