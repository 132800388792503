@use "~bootstrap/scss/bootstrap" as *;

// standardowe odstępy
$spacing-base: 1rem;
$spacing-extra-small: $spacing-base * 0.5;
$spacing-small: $spacing-base * 1;
$spacing-medium: $spacing-base * 2;
$spacing-large: $spacing-base * 3;
$spacing-extra-large: $spacing-base * 5.5;
$safety-margin-small: $spacing-base * 1;
$safety-margin: $spacing-base * 4.5;
$safety-margin-large: $spacing-base * 7;
$safety-margin-extra-large: $spacing-base * 20;
// Zmienne szerokości
$max-width: 1400px;
$container-padding: $spacing-base;
* {
  box-sizing: border-box;
}

html body {
  background-color: var(--background);
}
nav {
  margin-bottom: 3.5rem;
  box-shadow: 0 2px 6px var(--shadows);
  @include media-breakpoint-down(lg) {
    margin-bottom: 2rem;
  }
}
