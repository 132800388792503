@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.functions {
  display: flex;
  max-height: 25rem;
  min-height: 10rem;
  max-width: $max-width;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;

  @include media-breakpoint-up(lg) {
    margin-top: $safety-margin;
  }
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    margin: 0 $safety-margin-small;
    align-items: center;
    max-height: 55rem;
  }
  @include media-breakpoint-down(sm) {
    max-height: 75rem;
  }

  &__text {
    text-align: center;
    z-index: 2;
    flex: 1;
    min-width: 0;

    @include media-breakpoint-down(lg) {
      order: 2;
      text-align: center;
    }
  }

  &__title {
    @extend .headline-extra-large;
    margin-bottom: $spacing-small;
  }

  &__description {
    @extend .body-large;
    margin-bottom: $spacing-medium;
  }

  &__icons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: $spacing-small;
    width: 10rem;
    height: 9rem;
    background-color: var(--background);
    border-radius: 8px;
    padding: $spacing-small;
    box-shadow: 0 0 5px var(--shadows);
    color: var(--primary);
    transition: transform 0.2s;

    &--eco {
      color: var(--eco);
    }
    &:hover {
      transform: scale(1.1);
    }

    i {
      font-size: 3rem;
      margin-bottom: $spacing-small;
    }

    &-description {
      color: var(--primary);
      align-items: center;
      text-align: center;
      &--eco {
        color: var(--eco);
      }
    }
  }
}
