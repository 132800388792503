@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $spacing-large;
  background-color: var(--tertiary);
  margin-top: $safety-margin-small;

  @include media-breakpoint-down(lg) {
    margin-top: $safety-margin;
  }

  &__logo img {
    max-width: 10rem;
    height: auto;
  }

  &__links {
    margin-top: $spacing-medium;
    display: flex;
    gap: $spacing-small;
    align-items: center;
  }

  &__link {
    @extend .body-large;
    text-decoration: none;

    @include media-breakpoint-down(lg) {
      transition: color 0.3s;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      @include media-breakpoint-down(lg) {
        color: var(--primary);
      }
    }
  }

  &__social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-extra-small;
  }

  &__social-icon {
    margin-top: $spacing-medium;

    img {
      width: 55px;
      height: auto;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__ue-logo {
    margin-top: $spacing-medium;
    img {
      width: 35rem;
      height: auto;
    }
  }
  &__disclaimer {
    margin-top: $spacing-medium;
    margin-bottom: $safety-margin-small;
    text-align: center;

    &-text {
      @extend .body-small;
    }
  }
}
