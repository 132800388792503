$font-family: "Lexend", sans-serif;
$font-size-base: 1rem;
$font-size-root: 16px;
$extra-large-headline: $font-size-base * 3;
$large-headline: $font-size-base * 2;
$extra-large-text: $font-size-base * 1.5;
$large-text: $font-size-base * 1.25;
$medium-text: $font-size-base * 1;
$small-text: $font-size-base * 0.875;
$primary-text-color-light: #000;
$secondary-text-color-light: #636363;

$primary-text-color-dark: #fff;
$secondary-text-color-dark: #c7c7c7;
$link-color: #4f83f5;
html {
  font-size: $font-size-root;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
  }
}

@mixin text-style($size, $color, $weight) {
  font-size: $size;
  color: $color;
  font-family: $font-family;
  font-weight: $weight;
}

[data-theme="light"] {
  .headline-extra-large {
    @include text-style($extra-large-headline, $primary-text-color-light, 700);
  }
  .headline-large {
    @include text-style($large-headline, $primary-text-color-light, 500);
  }
  .body-large {
    @include text-style($large-text, $secondary-text-color-light, 500);
  }
  .body-medium {
    @include text-style($medium-text, $secondary-text-color-light, 400);
  }
  .body-small {
    @include text-style($medium-text, $secondary-text-color-light, 400);
  }
  .link-btn {
    @include text-style($medium-text, $link-color, 600);
  }
}

[data-theme="dark"] {
  .headline-extra-large {
    @include text-style($extra-large-headline, $primary-text-color-dark, 700);
  }
  .headline-large {
    @include text-style($large-headline, $primary-text-color-dark, 500);
  }
  .body-large {
    @include text-style($large-text, $secondary-text-color-dark, 500);
  }
  .body-medium {
    @include text-style($medium-text, $secondary-text-color-dark, 400);
  }
  .body-small {
    @include text-style($medium-text, $secondary-text-color-dark, 400);
  }
  .link-btn {
    @include text-style($medium-text, $link-color, 600);
  }
}
