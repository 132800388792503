@use "../theme/fonts" as *;
@use "../theme/variables" as *;
@use "~bootstrap/scss/bootstrap" as *;

.about-us {
  &__container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    @include media-breakpoint-down(lg) {
      margin-right: $safety-margin-small;
      margin-left: $safety-margin-small;
      flex-direction: column;
      max-height: 45rem;
      margin-bottom: $safety-margin;
    }
  }

  &__title {
    @extend .headline-extra-large;
    margin-bottom: $spacing-medium;
    margin-top: $safety-margin;
  }

  &__description {
    @extend .body-medium;
    line-height: 1.5;
    text-align: justify;
  }
  &__highlight {
    @extend .body-large;
  }
}
